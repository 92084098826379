@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #dbdbdb;
  font-family: "Roboto Slab", serif;
  width: 100vw;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body,
#App,
#root,
#App > div {
  min-height: 100%;
}
