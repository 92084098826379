.projects-container {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 5rem auto;
  width: 60%;
  border-bottom: 1px solid $color-main-nav;

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 5rem;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-evenly;
    width: 100%;
  }

  @media (max-width: 1500px) {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    width: 75%;
  }

  @media (max-width: 2000px) {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    width: 75%;
  }

  .main-card {
    margin-bottom: 5rem;
  }
}

.card-front {
  background-color: #dbdbdb;
  border-radius: 10px;
  height: 50rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include boxshadow();
  cursor: pointer;

  @media (max-width: 500px) {
    width: 35rem;
  }
  p {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  .more-info-btn {
    &:after {
      display: block;
      content: "";
      border-bottom: solid 1px $color-main-nav;
      transform: scaleX(0);
      transition: transform 400ms ease-in-out;
    }

    &:hover:after {
      transform: scaleX(1);
    }
  }

  button {
    position: absolute;
    bottom: 0;
    width: 8rem;
    margin-bottom: 2.5rem;
    cursor: pointer;
    border: 1px solid $color-main-nav;
    border-radius: 3px;
    padding: 5px 3px 5px 3px;
  }

  img {
    width: 100%;
    height: 65%;
    border-radius: 10px 10px 0px 0px;
  }

  h2 {
    margin-top: 3rem;
  }
}

.card-back {
  background-color: #dbdbdb;
  height: 50rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @include boxshadow();

  button {
    width: 8rem;
    cursor: pointer;
  }

  h1 {
    margin-right: 1rem;
  }

  .card-back-header {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    i {
      align-self: flex-end;
    }
  }

  .card-back-btns {
    margin-top: 2rem;
    padding-bottom: 3rem;
    display: flex;

    .icon-btns {
      text-align: center;
    }

    a {
      height: 2.5rem;
      width: 9rem;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 2rem;
      font-size: 1.5rem;
      outline: none;
      color: white;
      border: none;
      border-radius: 2px;
      opacity: 0.9;
      transition: 300ms;
      text-decoration: none;
      padding: 5px 5px 5px 5px;

      &:hover {
        cursor: pointer;
        opacity: 1;
        transition: 0.3s all;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .project-info {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    text-align: left;
    width: 75%;
    font-size: 1.5rem;
    overflow-y: scroll;
    @include custom-scroll-bar();
    padding-bottom: 80px;

    h2 {
      text-align: center;
    }

    p {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }

  .tech-stack-cont {
    display: flex;
    margin-top: 1rem;

    .front-end-stack {
      display: flex;
      border-right: 1px solid $color-main-nav;
      flex-direction: column;

      h2 {
        margin-right: 1rem;
      }

      li {
        list-style-type: none;
        margin-top: 0.5rem;
        margin-right: 10px;
        font-size: 1.5rem;
      }
    }

    .back-end-stack {
      display: flex;
      flex-direction: column;

      li {
        list-style-type: none;
        margin-top: 0.5rem;
        font-size: 1.5rem;
        margin-left: 1rem;
      }

      h2 {
        margin-left: 1rem;
      }
    }
  }
}
