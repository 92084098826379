@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");

.Skills-cont {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  width: 35%;
  margin: 0 auto;
  border-bottom: 0.5px solid $color-main-nav;

  div {
    margin-right: 1rem;
  }
  // Mobile
  @media (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 50%;
    justify-content: space-evenly;
    padding-top: 4rem;

    div {
      margin: 0 auto;
    }
  }
  // Tablet
  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin-left: 0.4rem;
    margin: 0 auto;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
  }

  p {
    text-align: center;
    font-size: 2.5rem;
    font-family: "Architects Daughter", cursive;
  }

  .skills-svg {
    height: 12rem;
    margin: 3rem 0 3rem 0;
  }
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 2rem;
    font-size: 2.5rem;
  }

  img {
    &:hover {
      cursor: pointer;
    }
  }
}
