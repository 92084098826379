.nav-bar {
  // background-color: $color-main-nav;
  height: 5rem;
  display: flex;

  @media (max-width: 500px) {
    margin-bottom: 15rem;
  }

  .nav-items {
    display: flex;

    @media (max-width: 500px) {
      flex-wrap: wrap;
      flex-basis: 30%;
      justify-content: center;
    }

    @media (max-width: 1000px) {
      margin: 0 auto;
    }
  }

  a {
    font-size: 2.5rem;
    text-decoration: none;
    margin-left: 2rem;
    color: $color-main-nav;

    &:after {
      display: block;
      content: "";
      border-bottom: solid 2px $color-main-nav;
      transform: scaleX(0);
      transition: transform 400ms ease-in-out;
    }

    &:hover:after {
      transform: scaleX(1);
    }
  }
}
