.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;

  .mobile-additional-contact {
    display: none;

    @media (max-width: 500px) {
      display: flex;
      margin-top: 3rem;
    }

    i {
      @media (max-width: 500px) {
        margin-right: 1rem;
        transition: 300ms;
        opacity: 0.9;

        &:hover {
          opacity: 1;
          transition: 0.3s all;
        }
      }
    }
  }

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .contact-info {
    height: 39rem;
    margin-left: 5rem;

    @media (max-width: 500px) {
      display: none;
    }

    i {
      color: $color-main-nav;
    }

    .dynamic-hover {
      a {
        &:after {
          display: block;
          content: "";
          border-bottom: solid 1px $color-main-nav;
          transform: scaleX(0);
          transition: transform 400ms ease-in-out;
        }

        &:hover:after {
          transform: scaleX(1);
        }
      }
    }

    .contact-methods {
      margin-top: 2rem;
      display: flex;
      font-size: 1.5rem;
      align-items: center;

      p {
        margin-left: 10px;
      }

      a {
        text-decoration: none;
        color: black;
      }
    }
  }

  .contact-form {
    // display: flex;
    // flex-direction: column;
    // width: 20%;
    // align-items: center;

    .name-form {
      display: flex;
      flex-direction: column;

      .first-name-input {
        display: flex;
        flex-direction: column;
      }

      .name-input {
        display: flex;
        flex-direction: column;
      }
    }

    span {
      color: $color-main-nav;
    }

    textarea {
      resize: none;
      &:hover {
        border: 1px solid $color-main-nav;
      }

      &:focus {
        outline: none;
      }
    }

    input {
      padding: 10px 5px 10px 5px;
      margin-bottom: 10px;
      border: 1px solid gray;
      border-radius: 2px;

      &:hover {
        border: 1px solid $color-main-nav;
      }

      &:focus {
        outline: none;
      }
    }

    label {
      margin-bottom: 5px;
      font-size: 1.5rem;
    }

    .email-input {
      display: flex;
      flex-direction: column;
      border-radius: 2px;
    }

    .message-input {
      display: flex;
      flex-direction: column;
      border-radius: 2px;

      .send-btn {
        margin-top: 1rem;
        height: 3rem;
        color: white;
        background-color: $color-main-nav;
        border: none;
        border-radius: 2px;
        opacity: 0.9;
        transition: 300ms;

        &:hover {
          cursor: pointer;
          opacity: 1;
          transition: 0.3s all;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
