.welcome-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: black;
  margin-bottom: 7rem;
  height: 30rem;

  @media (max-width: 500px) {
    text-align: left;
    width: 80vw;
    margin: 0 auto;
    margin-bottom: 30rem;
  }

  @media (max-width: 1000px) {
    text-align: center;
  }

  h1 {
    font-size: 4rem;
  }

  .header-container {
    white-space: pre-wrap;
    font-size: 1.5rem;
    display: flex;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    img {
      border-radius: 100%;
      margin-right: 2rem;

      @media (max-width: 500px) {
        margin-left: 5rem;
      }
    }

    p {
      font-weight: 200;
    }
  }
  .header-btns-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 3rem;

    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      margin-bottom: -15rem;
    }

    @media (max-width: 1000px) {
      display: flex;
      justify-content: center;
    }

    a {
      padding: 7px 6px 7px 6px;
      color: white;
      background-color: $color-main-nav;
      border: none;
      border-radius: 2px;
      opacity: 0.9;
      transition: 300ms;
      font-size: 1.5rem;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        opacity: 1;
        transition: 0.3s all;
      }
    }

    button {
      padding: 7px 6px 7px 6px;
      color: white;
      background-color: $color-main-nav;
      border: none;
      border-radius: 2px;
      opacity: 0.9;
      transition: 300ms;
      font-size: 1.5rem;

      &:hover {
        cursor: pointer;
        opacity: 1;
        transition: 0.3s all;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
