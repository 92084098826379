.title-header {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Roboto Slab", serif;
  display: flex;
  justify-content: center;
}

.title-header h3 {
  border-bottom: 0.5px solid #197278;
  margin-top: 5rem;
}

.particles {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

@media (max-width: 500px) {
  .title-header-skills h3 {
    margin-top: 0;
  }
}
