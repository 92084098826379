@mixin boxshadow() {
  box-shadow: 5px 7px 5px rgba(0, 0, 0, 0.25);
}

@mixin no-scroll-bar() {
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    width: 0;
  }
}

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 10px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #197278;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: white;
  }
}
